<template>
  <div>
    <div class="container">
      <b-card>
        <div class="row align-items-center">
          <div class="col-xl-2 col-md-2 col-4">
            <img class="app-icon w-100" :src="icon" alt="" />
          </div>
          <div class="col-8 col-md-10">
            <h2 class="d-flex justify-content-between">
              <span class="app-title">{{ title }}</span>
            </h2>
            <p class="app-summary" v-html="summary"></p>
            <b-button class="app-account buttonMd" @click="searchDev" style="cursor: pointer">
              {{ account }}
            </b-button>
          </div>
          <div class="buttonSm mt-1">
            <b-button class="app-account" @click="searchDev" style="cursor: pointer" block>
              {{ account }}
            </b-button>
          </div>
        </div>
      </b-card>
      <div class="app-info-wrapper">
        <b-card class="app-info-item" id="infoCards">
          <p class="appKey">Category</p>
          <p class="appValue">{{ genre }}</p>
        </b-card>
        <b-card class="app-info-item" id="infoCards">
          <p class="appKey">Price</p>
          <p class="appValue">{{ priceText }}</p>
        </b-card>
        <b-card class="app-info-item" id="infoCards">
          <p class="appKey">Downloads</p>
          <p class="appValue">{{ installs }}</p>
        </b-card>
        <b-card class="app-info-item" id="infoCards">
          <p class="appKey">Version</p>
          <p class="appValue">{{ androidVersionText }}</p>
        </b-card>
        <b-card class="app-info-item" id="infoCards">
          <p class="appKey">Score</p>
          <p class="appValue">{{ scoreText }}</p>
        </b-card>
        <!-- <b-card class="app-info-item" id="infoCards">
            <p class="appKey"></p>
            <p class="appValue">{{ appId }}</p>
          </b-card> -->
        <b-card class="app-info-item" id="infoCards">
          <p class="appKey">Open in</p>
          <p class="appValue">
            <a :href="playstoreUrl" target="_blank">
              <feather-icon icon="LinkIcon" size="20" /> Play Store
            </a>
          </p>
        </b-card>
      </div>
    </div>

    <b-container>
      <b-row>
        <b-col class="col-md-3">
          <b-card id="appInfoLabel">
            <h2 class="text-center">App Info</h2>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col cols="12" md="8" sm="12">
          <b-card id="appInfoMain">
            <template>
              <h3 class="mb-3">Screenshoots :</h3>
              <div class="image-container">
                <div class="image-wrapper">
                  <div v-for="(screenshot, index) in screenshots" :key="index" class="image-item">
                    <img :src="screenshot" alt="Screenshot" />
                  </div>
                </div>
              </div>
            </template>
            <hr class="my-3" />
            <div>
              <h3 class="mb-3">Description :</h3>
              <p v-html="description" :class="isArabicText(description) ? 'rtl' : 'ltr'"></p>
            </div>
          </b-card>

          <!-- <b-card>
            <h3 class="mb-2">Installs</h3>

            <select @change="setInstallsLastDays" class="form-control w-25 d-block ml-auto">
              <option value="7"> Last 7 Days</option>
              <option value="30"> Last 30 Days </option>
              <option value="60"> Last 60 Days </option>
            </select>
            <div class="row rowApex">
              <apexchart class="apexcharts-canvas" type="line" :options="installsChartOptions"
                :series="installsChartSeries">
              </apexchart>
            </div>
          </b-card>
          <b-card>
            <h3 class="mb-2">Reviews</h3>
            <select @change="setReviewsLastDays" class="form-control w-25 d-block ml-auto">
              <option value="7"> Last 7 Days</option>
              <option value="30"> Last 30 Days </option>
              <option value="60"> Last 60 Days </option>
            </select>
            <div class="row rowApex">
              <apexchart class="apexcharts-canvas" type="line" :options="reviewsChartOptions"
                :series="reviewsChartSeries">
              </apexchart>
            </div>
          </b-card> -->
        </b-col>
        <b-col cols="12" md="4" sm="12">
          <b-card>
            <div class="asideWrapper">
              <h3>About</h3>
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">Version</span>
                  <span class="text-secondary app-version">{{ version }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">Last Update</span>
                  <span class="text-secondary app-released-date">
                    {{ formatDate(updated) }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">Released</span>
                  <span class="text-secondary app-released-date">{{
                    releasedDate
                  }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center" v-if="refreshed">
                  <span class="font-weight-bold">Refreshed</span>
                  <span class="text-secondary app-refreshed-at">{{
                    moment(refreshed, "YYYY-MM-DD").format("MMM DD, YYYY")
                  }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">Ratings</span>
                  <span class="text-secondary app-ratings">{{ ratings }}</span>
                </li>
                <li class="appidLi list-group-item d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">Developer's Email</span>
                  <span class="app-dev-email">
                    <a :href="'mailto:' + developerEmail">Contact</a>
                  </span>
                </li>
                <li class="appidLi list-group-item d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">Developer's Website</span>
                  <span class="app-dev-site">
                    <a :href="developerWebsite" target="_blank">Visit <feather-icon icon="webIcon" /></a>
                  </span>
                </li>
                <li class="appidLi list-group-item d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">Content Rating</span>
                  <span class="app-content-rating text-secondary">{{
                    contentRating
                  }}</span>
                </li>
                <li class="appidLi list-group-item d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">bundle ID</span>
                  <span class="app-id" @click="copyToClipboard(this)" v-b-tooltip="{
                    title:
                      '<h6><strong>' +
                      appId +
                      '</strong></h6> (Click to copy)',
                    html: true,
                  }" variant="outline-success">
                    <feather-icon icon="ClipboardIcon" class="text-primary" size="20" />
                  </span>
                </li>
              </ul>
            </div>
          </b-card>

          <b-card>
            <h3>Ratings and reviews </h3>
            <div id="reviewsCard">
              <div class="table" v-for="review in reviewsListData" :key="review.userImage">

                <div class="table-row">
                  <div>
                    <img :src="review.userImage" alt="userProfile" style="border:none;border-radius: 50%;" width="40"
                      height="40" />
                    <span class="text-dark ml-1 font-weight-bold">{{ review.userName }}</span>
                  </div>
                  <div class="mt-1">
                    <p class="font-weight-bold">
                      <template>
                        <feather-icon v-for="i in 5" icon="StarIcon" :key="i"
                          :class="{ 'text-warning': i <= review.score, 'text-secondary': i > review.score }"></feather-icon>
                      </template>
                      {{ review.date.length > 10 ? review.date.substring(0, 16).replace('T', ' ') : review.date }}
                    </p>
                  </div>
                  <div>
                    <p class="font-weight-bolder text-dark">
                      {{ review.text }}
                    </p>
                    <hr class="w-75 mx-auto" />
                  </div>
                </div>
              </div>
            </div>
          </b-card>
          <b-card>
            <h3 class="mb-2 text-center">Permessions</h3>
            <div v-for="(groupedPermissions, type) in groupedData" :key="type">
              <h6 class="mt-1">
                <feather-icon icon="ArrowRightCircleIcon" size="20"></feather-icon>
                {{ type }}
              </h6>
              <ol class="list-group  list-group-numbered">
                <li v-for="permission in groupedPermissions" :key="permission" class="list-group-item">{{ permission }}
                </li>
              </ol>
            </div>
          </b-card>
          <b-card>
            <h3 class="mb-2">Similar Apps / Games</h3>
            <div>
              <div class="container-fluid">
                <div class="row ">
                  <div class="col-6 col-md-6 col-lg-4" v-for="app in similarListData" :key="app.appId">
                    <router-link :to="{
                      name: 'market-manager-accounts-search',
                      query: {
                        search: isNaN(app.appId)
                          ? app.developer.devId
                          : app.appId,
                      },
                    }" target="_blank">
                      <img :src="app.icon" alt="icon" class="similarAppIcon" />
                      <p style="font-size: 10px;font-weight: 800;" class="text-dark" v-b-tooltip.hover.bottom="{
                        title: '<strong>' + app.title + '</strong> ',
                        html: true,

                      }">
                        {{ app.title.length > 10 ? app.title.substring(0, 10) + '...' : app.title }}
                      </p>
                    </router-link>

                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import VueApexCharts from "vue-apexcharts";

import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BFormSelect,
  BContainer,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import { connectApi } from "@/helper/connectApi";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Chart from "chart.js";
import moment from "moment";
import { formatDate } from "@/@core/utils/filter";

export default {
  components: {
    // BCardCode,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BCardText,
    BContainer,
    apexchart: VueApexCharts,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      moment,
      appId: null,
      title: null,
      icon: null,
      score: null,
      releasedDate: null,
      version: null,
      summary: null,
      description: null,
      screenshots: null,
      installs: null,
      ratings: null,
      account: null,
      genre: null,
      refreshed: null,
      developer: {},
      priceText: null,
      installs: null,
      scoreText: null,
      updated: null,
      genre: null,
      androidVersionText: null,
      screenshoots: [],
      appId: null,
      playstoreUrl: null,
      idapp: null,
      developerEmail: null,
      developerWebsite: null,
      contentRating: null,
      showMore: false,
      reviewsListData: [],
      similarListData: [],
      permessionData: [],
      data: {},
      slide: 0,
      sliding: null,
      // validation rules
      required,
      regex,
      url,
      charts: false,
      installsFilter: "0",
      reviewsFilter: "0",
      installsData: [],
      reviewsData: [],
      installsDates: [],
      reviewsDates: [],
      installsLastDays: 7,
      reviewsLastDays: 7,
    };
  },
  watch: {
    installsLastDays: {
      immediate: true, // Trigger the watcher immediately on component mount
      handler(newVal, oldVal) {
        this.getInstallsData();
      },
    },
    reviewsLastDays: {
      immediate: true, // Trigger the watcher immediately on component mount
      handler(newVal, oldVal) {
        this.getReviewsData();
      },
    },
  },
  computed: {
    installsChartOptions() {
      return {
        chart: {
          id: "installs-chart",
        },
        colors: ["#ccc"],
        xaxis: {
          categories: this.installsDates,
        },

      };
    },
    reviewsChartOptions() {
      return {
        chart: {
          id: "reviews-chart",
        },
        colors: ["#ccc"],

        xaxis: {
          categories: this.reviewsDates,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value + 20;
            },
          },
        },
      };
    },
    installsChartSeries() {
      return [
        {
          name: "Installs",
          data: this.installsData,
          // data: [300,350,540,340,593],
        },
      ];
    },
    reviewsChartSeries() {
      return [
        {
          name: "Reviews",
          data: this.reviewsData,
          // data: [300,350,540,340,593],
        },
      ];
    },
    groupedData() {
      const groupedPermissions = {};

      this.permessionData.forEach((permission) => {
        if (!groupedPermissions[permission.type]) {
          groupedPermissions[permission.type] = [];
        }
        groupedPermissions[permission.type].push(permission.permission);
      });

      return groupedPermissions;
    },
  },
  // mounted() {
  //   this.token = JSON.parse(localStorage.getItem("userAccess")).token;
  //   this.server = JSON.parse(localStorage.getItem("userAccess")).server;
  //   this.getSelectedApp();
  //   setTimeout(() => {
  //     this.getInstallsData();
  //     this.getReviewsData();
  //     this.getReviewsFromApi();

  //   }, 3000);


  //   // this.getAppDetailData();
  // },
  mounted() {
    this.token = JSON.parse(localStorage.getItem("userAccess")).token;
    this.server = JSON.parse(localStorage.getItem("userAccess")).server;
    if (this.$route.params.from === "db") {
      this.getSelectedApp();
    } else {
      this.getSelectedAppFromApi();
    }

    setTimeout(() => {
      this.getReviewsFromApi();
      this.getInstallsData();
      this.getReviewsData();
    }, 3000);


    // this.getAppDetailData();
  },
  methods: {
    isArabicText(content) {
      const arabicRegex = /[\u0600-\u06FF]/;
      return arabicRegex.test(content);
    },
    copyToClipboard(element) {
      // Create a temporary input element
      const tempInput = document.createElement("input");
      tempInput.value = this.appId;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.showToast(
        "success",
        "SaveIcon",
        "Done",
        this.appId + " Copied to Clipboard"
      );
    },
    async searchDev() {
      try {
        await axios.get(`/get-developerId/${this.account}`).then((response) => {
          const developerId = response.data.account_id;
          const query = {
            search: isNaN(developerId) ? this.account : developerId,
          };
          let route = router.resolve({
            name: "market-manager-accounts-search",
            query: query,
            target: "_blank",
          });
          window.open(route.href);
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          const errorMessage = error.response.data.error;
          console.error(errorMessage);
        } else {
          console.error(error);
        }
      }
    },
    setInstallsLastDays(event) {
      const selectedValue = event.target.value;
      this.installsLastDays = selectedValue;
    },
    setReviewsLastDays(event) {
      const selectedValue = event.target.value;
      this.reviewsLastDays = selectedValue;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    truncateText(element) {
      const text = element.textContent;
      element.textContent = text.slice(0, 6);
    },
    toggleDescription() {
      this.showMore = !this.showMore;
    },

    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async getAppDetailData() {
      // axios
      //   .get(
      //     `https://sc${this.server}.clicklab.app/api/apps/${this.appId}/?fullDetail=true`
      //   )
      const response = connectApi(`https://sc${this.server}.clicklab.app/api/apps/${this.appId}/?fullDetail=true`);
      response
        .then((response) => {
          const { status } = response.data;
          if (status === "Unauthorized") {
            removeLocalStorageItems();
            this.$root.$emit("bv::show::modal", "modal-session-app");
            return;
          }
          const data = response.data;
          return data;
        })
        .then((data) => {
          this.title = data.title;
          this.icon = data.icon;
          this.releasedDate = data.released;
          this.summary = data.summary;
          this.version = data.version;
          this.installs = data.maxInstalls;
          this.ratings = data.ratings;
          this.account = data.developer.devId;
          this.screenshots = data.screenshots;
          this.priceText = data.priceText;
          this.installs = data.installs;
          this.genre = data.genre;
          this.scoreText = data.scoreText;
          this.description = data.descriptionHTML;
          this.androidVersionText = data.androidVersionText;
          this.playstoreUrl = data.playstoreUrl;
          this.appId = data.appId;
          this.updated = data.updated;
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "can't fetch the data!"
          );
        });
    },

    getReviewsData() {
      axios
        .post(`/get-app-reviews`, {
          appId: this.idapp,
          period: this.reviewsLastDays,
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            this.$router.push({ name: "auth-login" });
          } else {
            var formatedData = [];
            data.created_at.forEach((element) => {
              let dateF = formatDate(element);
              formatedData.push(dateF);
            });
            this.reviewsDates = formatedData;
            this.reviewsData = data.reviews;
          }
        })
        .catch((exception) => console.error(exception));
    },

    getInstallsData() {
      axios
        .post(`/get-app-installs`, {
          appId: this.idapp,
          period: this.installsLastDays,
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            this.$router.push({ name: "auth-login" });
          } else {
            let formatedData = [];
            data.created_at.forEach((element) => {
              let dateF = formatDate(element);
              formatedData.push(dateF);
            });
            this.installsDates = formatedData;
            this.installsData = data.installs;
            const xaxisElement = document.querySelectorAll(
              "text.apexcharts-xaxis-label"
            );
            xaxisElement.forEach((element) => {
              truncateText(element);
            });
          }
        })
        .catch((exception) => console.error(exception));
    },

    // drawInstallsChart(labels, data) {
    //   const ctx = this.$refs.installsChart;
    //   new Chart(ctx, {
    //     type: "line",
    //     data: {
    //       labels: labels,
    //       datasets: [
    //         {
    //           label: "# Installs",
    //           data: data,
    //           borderWidth: 2,
    //           borderColor: "#4bc0c0",
    //           backgroundColor: "#4bc0c030",
    //           hoverBackgroundColor: "#FFF",
    //           fill: "start",
    //         },
    //       ],
    //     },
    //     options: {
    //       scales: {
    //         yAxes: [
    //           {
    //             ticks: {
    //               beginAtZero: true,
    //               min: 0, // Start the y-axis at 0
    //               max: labels.length, // Set the maximum value to the total number of days
    //               stepSize: 1, // Set the step size to 1
    //             },
    //           },
    //         ],
    //         xAxes: [
    //           {
    //             ticks: {
    //               beginAtZero: true,
    //               min: 20, // Start the x-axis at 20 installs per day
    //               max: 100, // Set the maximum value to 100 installs per day
    //               stepSize: 20, // Set the step size to 20
    //             },
    //           },
    //         ],
    //       },
    //     },
    //   });
    // },

    // drawReviewsChart(labels, ratings, scores) {
    //   const ctx = this.$refs.ReviewsChart;
    //   new Chart(ctx, {
    //     type: "line",
    //     data: {
    //       labels: labels,
    //       datasets: [
    //         {
    //           label: "# Reviews",
    //           data: ratings,
    //           yAxisID: "ratings-y-axis",
    //           // showLine: true,
    //           borderWidth: 2,
    //           borderColor: "#4bc0c0",
    //           backgroundColor: "#4bc0c030",
    //           hoverBackgroundColor: "#FFF",
    //         },
    //         {
    //           label: "# Scores",
    //           data: scores,
    //           yAxisID: "scores-y-axis",
    //           borderWidth: 2,
    //           borderColor: "rgb(255, 193, 7)",
    //           backgroundColor: "rgba(255, 193, 7, .25)",
    //           hoverBackgroundColor: "#FFF",
    //         },
    //       ],
    //     },
    //     options: {
    //       scales: {
    //         yAxes: [
    //           {
    //             id: "ratings-y-axis",
    //             type: "linear",
    //             position: "left",
    //             // display: false,
    //             gridLines: {
    //               drawOnChartArea: false,
    //               // drawTicks: false,
    //             },
    //             ticks: {
    //               // display: false,
    //               beginAtZero: true,
    //             },
    //           },
    //           {
    //             id: "scores-y-axis",
    //             type: "linear",
    //             position: "right",
    //             // display: false,
    //             gridLines: {
    //               drawOnChartArea: false,
    //               // drawTicks: false,
    //             },
    //             ticks: {
    //               // display: false,
    //               beginAtZero: true,
    //               suggestedMin: 0,
    //               suggestedMax: 5,
    //             },
    //           },
    //         ],
    //         xAxes: [
    //           {
    //             // // display: false,
    //             // gridLines: {
    //             //   drawOnChartArea: false,
    //             //   // drawTicks: false,
    //             // },
    //           },
    //         ],
    //         // y: {
    //         //   beginAtZero: true,
    //         // },
    //       },
    //     },
    //   });
    // },

    async getSelectedApp() {
      await axios
        .post(`/get-app`, {
          app: this.$route.params.id,
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
        .then(({ data }) => {
          const { app } = data;
          this.idapp = app.id;
          this.appId = app.appId;
          this.title = app.title;
          this.icon = app.icon;
          this.releasedDate = app.released;
          this.score = app.scoreText;
          this.summary = app.summary;
          this.version = app.version;
          this.ratings = app.ratings;
          this.account = JSON.parse(app.developer).devId;
          this.genre = app.genre;
          this.screenshots = JSON.parse(app.screenshots);
          this.priceText = app.priceText;
          this.installs = app.Installs;
          this.genre = app.genre;
          this.scoreText = app.scoreText;
          this.description = app.descriptionHTML;
          this.androidVersionText = app.androidVersionText;
          this.playstoreUrl = app.playstoreUrl;
          this.appId = app.appId;
          this.updated = parseInt(app.updated);
          this.developerEmail = app.developerEmail;
          this.developerWebsite = app.developerWebsite;
          this.contentRating = app.contentRating;
          // this.getInstallsData();
          // this.getReviewsData();
        })

        .catch(console.error);
    },
    async getReviewsFromApi() {
      // await axios
      //   .get(
      //     `https://sc${this.server}.clicklab.app/api/apps/${this.appId}/?fullDetail=true`
      //   )
      const response = await connectApi(`https://sc${this.server}.clicklab.app/api/apps/${this.appId}/?fullDetail=true`);
      response
        .then((response) => {
          const { status } = response.data;
          if (status === "Unauthorized") {
            removeLocalStorageItems();
            this.$root.$emit("bv::show::modal", "modal-session-app");
            return;
          }
          const data = response.data;
          return data;
        })
        .then(async (data) => {
          // GET ALL REVIEWS 
          var { reviews: link } = data;
          link = link.split(":")
          link[0] = "https:";
          link = link[0] + link[1]
          await axios.get(`${link}?token=${this.token}&from=am`).then((response) => {
            const reviewsList = response.data;
            this.reviewsListData = reviewsList.results.data.slice(0, 20)
          })
          // GET ALL SIMILAR APPS
          var { similar: linkSimilar } = data;
          linkSimilar = linkSimilar.split(":")
          linkSimilar[0] = "https:";
          linkSimilar = linkSimilar[0] + linkSimilar[1]
          await axios.get(`${linkSimilar}?token=${this.token}&from=am`).then((response) => {
            const similarApps = response.data;
            this.similarListData = similarApps.results.slice(0, 9)
          })
          // GET ALL PERMISSIONS
          var { permissions: linkPermissions } = data
          linkPermissions = linkPermissions.split(":")
          linkPermissions[0] = "https:";
          linkPermissions = linkPermissions[0] + linkPermissions[1]
          await axios.get(`${linkPermissions}?token=${this.token}&from=am`).then((response) => {
            const permessions = response.data;
            this.permessionData = permessions.results.slice(0, 9)
          })
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "can't fetch the data!"
          );
        });
    },
    async getSelectedAppFromApi() {
      // axios
      //   .get(
      //     `https://sc${this.server}.clicklab.app/api/apps/${this.$route.params.id}/?fullDetail=true`
      //   )
      const response = connectApi(`https://sc${this.server}.clicklab.app/api/apps/${this.$route.params.id}/?fullDetail=true`);
      response
        .then((response) => {
          const { status } = response.data;
          if (status === "Unauthorized") {
            removeLocalStorageItems();
            this.$root.$emit("bv::show::modal", "modal-session-app");
            return;
          }
          const data = response.data;
          return data;
        })
        .then((data) => {
          this.playstoreUrl = data.playstoreUrl;
          this.title = data.title;
          this.icon = data.icon;
          this.appId = data.appId;
          this.releasedDate = data.released;
          this.score = data.scoreText;
          this.summary = data.summary;
          this.version = data.version;
          this.installs = data.maxInstalls;
          this.ratings = data.ratings;
          this.account = data.developer.devId;
          this.genre = data.genre;
          this.screenshots = data.screenshots;
          this.developer = data.developer;
          this.priceText = data.priceText;
          this.installs = data.installs;
          this.genre = data.genre;
          this.scoreText = data.scoreText;
          this.description = data.description;
          this.androidVersionText = data.androidVersionText;
          this.updated = data.updated;
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "can't fetch the data!"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 40px !important;
  height: 46px !important;
  background-color: #555;
}

.appKey {
  font-size: 1rem;
  padding-top: 12px !important;
  padding-bottom: 0 !important;
}

.appValue {
  padding-top: 0 !important;
  padding-bottom: 5px;
  font-size: 1.1rem;
  font-weight: 900 !important;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.app-summary {
  font-size: 15px;
  font-weight: 500;
  animation: myAnimation 2s;
}

@keyframes myAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.app-account {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-container {
  overflow-x: auto;
  display: flex;
}

.image-wrapper {
  display: flex;
}

.image-item {
  flex: 0 0 auto;
  margin-right: 10px;
  max-width: 100%;
}

.image-item img {
  width: 100%;
  height: 70vh;
  // max-width: 250px;
}

.image-container::-webkit-scrollbar {
  width: 5px;
}

.image-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; //
}

.image-container::-webkit-scrollbar-thumb {
  background-color: #050404;
  border-radius: 5px;
}

.image-container::-webkit-scrollbar-thumb:hover {
  background-color: rgb(7, 7, 60);
}

.image-container::-webkit-scrollbar-button {
  display: none;
}

.appInfoTitle {
  font-size: 1.6rem;
  font-weight: 900;
  width: fit-content;
  margin: 0 !important;
  color: #0764f9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12vh;
}

.showMoreLess {
  font-size: larger;
  font-weight: 800;
}

.asideWrapper {
  margin-block: 1rem;
}

.asideWrapper h3 {
  text-align: center;
}

.rowApex {
  position: relative;
  // opacity: 0.3;
}

.rowApex::before {
  content: "";
  display: block;
  padding-top: 65%;
}

.apexcharts-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
}

#infoCards {
  padding: 0 !important;
}

#infoCards .card-body {
  padding: 0;
}

.app-info-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .app-info-wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 500px) {
  .app-info-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;

  }

  #infoCards {
    margin: 3px !important;
  }

  #infoCards .card-body p {
    margin-bottom: 2px;
  }
}

.starIcon {
  color: rgb(255, 183, 0);
  fill: rgb(255, 174, 0);
  width: 20px !important;
  height: 20px !important;
}

text {
  fill: rgb(149, 149, 149) !important;
}

// text.apexcharts-xaxis-label {
//   visibility: hidden;
// }
.apexcharts-toolbar {
  display: none;
}

#appInfoLabel {
  margin: 0;
  padding: 0;
}

#appInfoLabel .card-body {
  margin: 0;
  padding: 10px 3px;
}

#appInfoMain,
#appInfoLabel {
  border-radius: 0;
}

#reviewsCard {
  height: 60vh;
  overflow-y: scroll;
  margin-top: 20px;

}

#reviewsCard::-webkit-scrollbar {
  width: 8px;
}

#reviewsCard::-webkit-scrollbar-track {
  background-color: transparent; //
}

#reviewsCard::-webkit-scrollbar-thumb {
  background-color: #050404;
  border-radius: 5px;
}

#reviewsCard::-webkit-scrollbar-thumb:hover {
  background-color: rgb(7, 7, 60);
}

#reviewsCard::-webkit-scrollbar-button {
  display: none;
}

.review-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.review-text {
  flex: 1;
  margin-right: 10px;
}

.review-stars {
  display: flex;
  align-items: center;
}

.review-stars p {
  margin-bottom: 5px;
}

.review-date {
  color: #888;
}

.table {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 5px;
  width: 50%;
}

@media screen and (max-width: 500px) {
  .buttonSm {
    display: block;
    width: 100%;
  }

  .buttonMd {
    display: none;
  }
}

@media screen and (min-width: 500px) {
  .buttonSm {
    display: none;
  }

  .buttonMd {
    display: block;
  }
}

.buttonSm button,
.buttonMd button {
  background-color: transparent !important;
}

.rtl {
  direction: rtl;
  text-align: justify;
}

.similarAppIcon {
  width: 100%;
  border-radius: 50%;
}
</style>
